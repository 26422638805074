
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import { colors } from '../Assets/styles/colors';
import QuickChatDetailsPage from './QuickChatDetailsPage';
import { useAuthService } from '../Context/AuthContext';
import QuickChatClosedPage from './QuickChatClosedPage';
import QuickChat from '../Components/QuickChat/QuickChat';
import { useQuickChatService } from '../Context/QuickChatContext';
import { notifyError } from '../Helpers/Notifications';
import { useParams } from 'react-router';
import { localizationStrings } from '../Localization/Localization';


const QuickChatPage = ({ setLoading }) => {

  const { getTranslationsLanguages } = useAuthService();
  const { quickChat, getQuickChat, anonymousUserExist, joinQuickChat, getMessagesQuickChat } = useQuickChatService();
  const [language, setLanguage] = useState(null);
  const [name, setName] = useState("");
  const { hashId } = useParams();

  const handleGetQuickChat = (hashId) => {
    setLoading(true);
    getQuickChat(
      hashId,
      (response) => {
        setLoading(false);
      },
      (error) => {
        if (error.response?.data?.message === "Conversation not open") {
          console.log(error);
          setLoading(false);
          return
        }
        notifyError(localizationStrings.profile.something_wrong);
        console.log(error);
        setLoading(false);
      }
    )
  }

  const handleJoinQuickChat = (hashId, name, language) => {
    setLoading(true);
    joinQuickChat(
      hashId, name, language,
      (response) => {
        // setLoading(false);
        handleGetQuickChat(hashId);
      },
      (error) => {
        setLoading(false)
        console.log(error);
        notifyError(localizationStrings.profile.something_wrong);
      }
    )
  }

  const handleContinue = () => {
    handleJoinQuickChat(hashId, name, language,);
  }

  useEffect(() => {
    getTranslationsLanguages(function () { },
      function () { }
    )
  }, [])

  useState(() => {
    handleGetQuickChat(hashId);
  }, [hashId])

  useEffect(() => {
    function blurInputs() {
      const inputs = document.querySelectorAll("input");

      inputs.forEach((input) => {
        input.blur();
      });
    }
    document.addEventListener("touchmove", blurInputs);

  }, [])



  return (
    <Container>

      {quickChat != null &&
        <>
          {
            anonymousUserExist ?
              <QuickChat setLoading={setLoading} />
              :
              <QuickChatDetailsPage
                name={name}
                setLanguage={setLanguage}
                setName={setName}
                language={language}
                handleContinue={handleContinue}
                quickChat={quickChat}
              />
          }
        </>
      }
      {quickChat === null && <QuickChatClosedPage />}



    </Container>

  )
}

export default QuickChatPage
const Container = styled.div`
      background:${colors.quickChatBg};
      display:flex;
      flex-direction:column;
      width:100%;
      max-height:100svh;
      height:100%;
      `;
