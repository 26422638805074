import Recorder from "recorder-js";

class RecorderService {
    constructor() {
        this.audioContext = null;
        this.recorder = null;
        this.stream = null;
        this.analyser = null;
        this.dataArray = null;
        this.speakingThreshold = 0.01; // Adjust this threshold as needed
        this.isSpeaking = false;
        this.silenceTimeout = null; // Timer for detecting end of speech
        this.silenceDelay = 1000; // 1 second delay to detect silence
        this.recordingStoppedCallback = null;
        this.resetRecordingInterval = null;
        this.resetRecordingDelay = 1000;
        this.isMonitoring = false;
    }

    async init() { }

    async start(callback) {
        this.recordingStoppedCallback = callback;

        try {
            this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            this.audioContext = new (window.AudioContext ||
                window.webkitAudioContext)();
            this.recorder = new Recorder(this.audioContext);
            await this.recorder.init(this.stream);

            // Setup analyser to monitor audio levels
            this.analyser = this.audioContext.createAnalyser();
            this.analyser.fftSize = 256;
            const bufferLength = this.analyser.frequencyBinCount;
            this.dataArray = new Uint8Array(bufferLength);

            const source = this.audioContext.createMediaStreamSource(this.stream);
            source.connect(this.analyser);

            this.resetRecordingInterval = setInterval(() => {
                if (!this.isSpeaking) {
                    this.stopRecording(false);
                    this.startRecording();
                }
            }, this.resetRecordingDelay);

            // Start monitoring audio levels
            this.isMonitoring = true;
            this.monitorAudio();

            // Start recording
            await this.startRecording();
        } catch (err) {
            console.error("Error initializing RecorderService", err);
        }
    }

    async stop() {
        this.cleanup();
        await this.stopRecording(false);
        clearInterval(this.resetRecordingInterval);
        this.isMonitoring = false;
    }

    hasStartedSpeaking() {
        // console.log("start");
    }

    hasEndedSpeaking() {
        this.stopRecording();
    }

    monitorAudio() {
        const detectSpeech = () => {
            // Check if monitoring is stopped
            if (!this.isMonitoring) {
                return; // Exit if not monitoring
            }

            this.analyser.getByteTimeDomainData(this.dataArray);
            let sum = 0.0;

            // Calculate the average audio level
            for (let i = 0; i < this.dataArray.length; i++) {
                const value = this.dataArray[i] / 128.0 - 1.0; // Normalize between -1.0 and 1.0
                sum += value * value;
            }
            const averageVolume = Math.sqrt(sum / this.dataArray.length); // RMS

            // Check if we're speaking
            if (averageVolume > this.speakingThreshold) {
                if (!this.isSpeaking) {
                    // console.log("Started speaking");
                    this.isSpeaking = true;
                    this.hasStartedSpeaking();
                }

                // Clear any existing timeout for silence
                if (this.silenceTimeout) {
                    clearTimeout(this.silenceTimeout);
                    this.silenceTimeout = null; // Reset timeout reference
                }
            } else if (this.isSpeaking) {
                // If speaking has stopped, set a timeout to check for silence
                if (!this.silenceTimeout) {
                    this.silenceTimeout = setTimeout(() => {
                        // console.log("Stopped speaking");
                        this.isSpeaking = false;
                        this.hasEndedSpeaking();
                        this.silenceTimeout = null; // Reset timeout reference
                    }, this.silenceDelay);
                }
            }

            // Keep monitoring
            requestAnimationFrame(detectSpeech);
        };

        detectSpeech();
    }

    async startRecording() {
        if (this.recorder) {
            try {
                await this.recorder.start();
                // console.log("Recording started");
            } catch (err) {
                console.error("Error starting recording", err);
            }
        }
    }

    async stopRecording(withCallback = true) {
        if (this.recorder) {
            try {
                const { blob } = await this.recorder.stop();
                if (this.recordingStoppedCallback && withCallback) {
                    this.recordingStoppedCallback(blob); // Call the stored callback with the blob
                }
            } catch (err) {
                console.error("Error stopping recording", err);
                return null;
            }
        }
        return null;
    }

    cleanup() {
        if (this.audioContext && this.audioContext.state === 'running') {
            this.audioContext.close();
        }
        if (this.stream) {
            this.stream.getTracks().forEach((track) => track.stop());
        }
    }
}

export default RecorderService;