import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import SpinnerComponent from '../Components/GenericComponents/SpinnerComponent';
import { useAuthService } from '../Context/AuthContext';
import { useNavigate } from 'react-router';
import LocalStorage from '../Helpers/LocalStorage';

const LogoutDeletedUser = () => {

    const { handleLogoutUser } = useAuthService()
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)

    const Logout = () => {
        handleLogoutUser();
    }

    useEffect(() => {
        Logout();
        navigate("/login")
    }, [])

    return (
        <SpinnerComponent isLoading={isLoading}>
            <Container>
            </Container>
        </SpinnerComponent>
    )
}

export default LogoutDeletedUser
const Container = styled.div`
display:flex;
max-height:100vh;
height:100%;
min-height:600px;
width:100%;
align-items:center;
overflow:auto;
position:relative;
`
