import React from 'react'
import styled from 'styled-components'
import { ReactComponent as CloseIcon } from "../../Assets/icons/closeIcon.svg";
import { colors } from '../../Assets/styles/colors';
import LocalStorage from '../../Helpers/LocalStorage';
import { localizationStrings } from '../../Localization/Localization';

const FirstTimeUsingMessage = ({ setShowFirstUseMessage }) => {
    const handleClose = () => {
        LocalStorage.setFirstMessageRead(true)
        setShowFirstUseMessage(true)
    }

    return (
        <Container>
            <CloseBtn onClick={handleClose}>
                <CloseIcon />
            </CloseBtn>
            <Line>
                <Title>{localizationStrings.first_time_usage.title}</Title>

            </Line>
            <Line>
                <Text>{localizationStrings.first_time_usage.subtitle}</Text>
            </Line>

            <Group>
                <Text><span>{localizationStrings.first_time_usage.step1Span}</span>: {localizationStrings.first_time_usage.step1}</Text>
                <Text><span>{localizationStrings.first_time_usage.step2Span}</span>: {localizationStrings.first_time_usage.step2}</Text>
                <Text><span>{localizationStrings.first_time_usage.step3Span}</span>: {localizationStrings.first_time_usage.step3}</Text>
            </Group>
            <Line>
                <Text><span>{localizationStrings.first_time_usage.proTipSpan}</span>: {localizationStrings.first_time_usage.proTip}</Text>
            </Line>
            <Line>
                <Text>{localizationStrings.first_time_usage.enjoy}</Text>
            </Line>

        </Container>
    )
}

export default FirstTimeUsingMessage


const Container = styled.div`
display:flex;
flex-direction:column;
gap:20px;
background:${colors.modalDarkPurpleBg};
padding:20px;
border-radius:10px;
box-shadow: 0px 0px 11.3px 0px ${colors.modalDarkPurple};
position:relative;
`
const Line = styled.div`
display:flex;
align-items:center;
gap:5px;
`
const Group = styled.div`
display:flex;
flex-direction:column;
gap:5px;
`


const CloseBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  cursor: pointer;
  margin-left: auto;
  max-width: 24px;
  position:absolute;
  top:-10px;
  right:-10px;
`;
const Title = styled.h1`
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  color: ${colors.white};
`;
const Text = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: ${colors.white};

  span{
    color: ${colors.modalGreenColor};
    font-weight:600;
  }
`