import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";
import { ReactComponent as CloseIcon } from "../../Assets/icons/closeIcon.svg";
import { ReactComponent as Crown } from "../../Assets/icons/subscription/crown.svg";

import { useAuthService } from "../../Context/AuthContext";
import moment from "moment";
import DeleteConfirmationChild from "../GenericComponents/DeleteConfirmationChild";
import SubscriptionPlanPicker from "../Subscription/SubscriptionPlanPicker";
import SubscribedPlanCard from "../Subscription/SubscribedPlanCard";
import { localizationStrings } from "../../Localization/Localization";

const SubscriptionModal = ({
  handleClose,
  handleSubscribeClick,
  handleUnSubscribeClick,
  handleSetInvitations,
}) => {
  const { hadFreeTrial, userCustomerInfo, getPlatformSubscription } = useAuthService();
  const [subscribeTo, setSubscribeTo] = useState("monthly")
  const [userHadFreeTrial, setUserHadFreeTrial] = useState(null);
  const [objKeys, setObjKeys] = useState([]);
  const [subscription, setSubscription] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);


  const SubscribeTitle = localizationStrings.subscription_modal.subscribe_title;
  const SubscribedTitle = localizationStrings.subscription_modal.subscribed_title;

  const JoinText =
    localizationStrings.subscription_modal.join_text
  const JoinedTex =
    localizationStrings.subscription_modal.joined_text

  const navigateToLandingPageLink = (to) => {
    let url = "http://emotii.ai";
    window.open(`${url}/${to}`);
  };

  const handleHadFreeTrial = () => {
    hadFreeTrial(
      (response) => {
        const resp = response.data.had_free_trial;
        setUserHadFreeTrial(resp);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handleGetPlatformSubscription = () => {
    getPlatformSubscription(
      (response) => {
        const resp = response.data.platform_subscription
        resp.productIdentifier = "subscription_from_platform"
        if (resp.active && resp.status === "active") {
          setSubscription(resp);
        }
      },
      (error) => { },
    )
  }

  const handleKeys = () => {
    if (!userCustomerInfo) {
      return;
    }
    let objKeys = Object.keys(userCustomerInfo.entitlements.active);
    setObjKeys(objKeys);
  };

  useEffect(() => {
    if (!userCustomerInfo) {
      return;
    }

    if (userCustomerInfo.entitlements.active.Pro && userCustomerInfo.entitlements.active.Pro.isActive) {
      setSubscription(userCustomerInfo.entitlements.active.Pro)
    } else if (userCustomerInfo.entitlements.active.Referrals && userCustomerInfo.entitlements.active.Referrals.isActive) {
      setSubscription(userCustomerInfo.entitlements.active.Referrals)
    } else {
      handleGetPlatformSubscription();
    }


    handleKeys();
  }, [userCustomerInfo]);


  useEffect(() => {
    handleHadFreeTrial();
  }, []);

  return (
    <FullScreenItem>
      <Card>
        <Line>
          <Title>{subscription ?
            <>
              <Crown style={{ height: "24px" }} />
              {SubscribedTitle}
            </>
            :
            SubscribeTitle}</Title>
          <CloseBtn onClick={handleClose}>
            <CloseIcon />
          </CloseBtn>
        </Line>

        <Line>
          <InfoText> {subscription ?
            JoinedTex :
            JoinText}</InfoText>
        </Line>

        {subscription ? (
          <SubscribedPlanCard
            subscription={subscription}
          />
        ) : (
          <SubscriptionPlanPicker subscribeTo={subscribeTo} setSubscribeTo={setSubscribeTo} />
        )}

        {subscribeTo === "invite" ?
          (
            <CustomBtn onClick={() => handleSetInvitations()}>
              {localizationStrings.subscription_modal.send_invitations}
            </CustomBtn>
          ) :

          (
            <Line>
              {subscription ? (
                subscription.willRenew ? (
                  subscription.store === "stripe" && (
                    <DeleteConfirmationChild
                      action={handleUnSubscribeClick}
                      setOpen={setOpenConfirmation}
                      open={openConfirmation}
                      variant={"unsubscribe"}
                    >
                      <CustomBtn onClick={() => setOpenConfirmation(true)}>
                        {localizationStrings.subscription_modal.unsubscribe}
                      </CustomBtn>
                    </DeleteConfirmationChild>
                  )
                ) : (
                  subscription.expirationDate &&
                  <InfoText style={{ color: colors.purple }}>
                    {localizationStrings.subscription_modal.active_until}{" "}
                    {moment(subscription.expirationDate).format("DD/MM/YYYY")}
                  </InfoText>

                )
              ) : (
                <CustomBtn onClick={() => handleSubscribeClick(subscribeTo)}>
                  {userHadFreeTrial === false && localizationStrings.subscription_modal.start3Days}
                  {userHadFreeTrial === true && localizationStrings.subscription_modal.subscribe}
                </CustomBtn>
              )}
            </Line>


          )
        }



        <Line>
          <TermsBtn onClick={() => navigateToLandingPageLink("terms/")}>
            {localizationStrings.subscription_modal.terms}
          </TermsBtn>
        </Line>
      </Card>
    </FullScreenItem>
  );
};

export default SubscriptionModal;

const FullScreenItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  left: 0;
  top: 0;
  padding:20px;
  z-index: 2;
`;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 200px;
  max-width: 500px;
  width: 100%;
  max-height:100%;
  background: ${colors.newChatModalBg};
  border-radius: 8px;
  padding: 30px;

  position: relative;
  gap: 20px;
  overflow:auto;
`;
const Line = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  align-items: center;
  svg {
    max-height: 40px;
    width: fit-content;
  }
`;

const Title = styled.h1`
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: ${colors.white};
  align-items:center;
  display:flex;
  gap:10px;
`;

const CustomBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: ${colors.white};
  border-radius: 10px;
  border: none;
  background: ${(props) => (props.outlined ? "none" : colors.darkPurple)};
  padding: 14px;
  cursor: pointer;
  border: 1px solid ${colors.darkPurple};
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  &:hover {
    background: ${(props) =>
    props.outlined ? colors.chatsListBg : colors.purple};
    border: 1px solid ${colors.purple};
  }
`;

const CloseBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  cursor: pointer;
  margin-left: auto;
  max-width: 32px;
`;
const InfoText = styled.h1`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: ${colors.chatElementColor};
`;
const SmallInfor = styled(InfoText)`
  font-size: 12px;
  line-height: 18px;
`;




const TermsBtn = styled(SmallInfor)`
  cursor: pointer;
  color: ${colors.purple};
`;
