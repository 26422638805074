import axios from "axios";
import LocalStorage from "../Helpers/LocalStorage";
const isElectron = !!window.electron;

const URL = process.env.REACT_APP_API_URL;

var refreshTokenConfig = (refreshtoken) => {
    var config = {
        method: "POST",
        url: `${URL}/api/v1/identity/users/refresh_sso_token`,
        headers: {
            platform: isElectron ? "desktop" : "web",
            Authorization: `Bearer ${LocalStorage.getAuthenticationToken()}`,
        },

        data: {
            refresh_token: refreshtoken,
        },

    };
    return config;
};


export const refreshSSOToken = (refreshtoken, onSuccess, onError) => {

    axios(refreshTokenConfig(refreshtoken))
        .then(function (response) {
            LocalStorage.setAuthenthicationToken(response.data.token);
            LocalStorage.setRefreshToken(response.data.refresh_token);
            onSuccess(response);
        })
        .catch(function (error) {
            onError(error);
        });
};
