import React from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";

const ControlButton = ({
  icon,
  active,
  translationEnabled,
  label,
  ...props
}) => {
  return (
    <Wrapper>
      <CustomButton
        {...props}
        $active={active}
        $translationEnabled={translationEnabled}
      >
        {icon}
      </CustomButton>
      <CustomLabel>{label}</CustomLabel>
    </Wrapper>
  );
};

export default ControlButton;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  min-height: 83px;
  align-items: center;
  gap: 5px;
`;
const CustomButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.$translationEnabled ? "60px" : "60px")};
  width: ${(props) => (props.$translationEnabled ? "60px" : "60px")};
  border-radius: 100%;
  background: ${(props) =>
    props.$active ? colors.white : colors.controlBtn.inactiveBg};
  border: none;
  cursor: pointer;
  overflow: hidden;
  color: ${(props) =>
    props.$active ? colors.controlBtn.inactiveBg : colors.white};
`;

const CustomLabel = styled.label`
  font-size: 14px;
  color: ${colors.modalInfoTextColor};
  line-height: 18px;
`;
