import React, { useRef } from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";
import { displayAvatar } from "../../Helpers/chatHelper/AvatarHelper";

const ForwardModalChatElement = ({ data, selected, handleContactSelect }) => {
  const ref = useRef();
  let name = data?.other_participants[0]?.name;

  if (data.group != null) {
    name = data.group.name;
  }
  if (data.quick_chat) {
    name = data.quick_chat_name;
  }

  return (
    <ChatListElementContainer onClick={(e) => handleContactSelect(data)}>
      <UserAvatar>{displayAvatar(data)}</UserAvatar>
      <Text>
        <PhoneNumber>
          <h1>{name}</h1>
        </PhoneNumber>
        <CustomCheckBox>
          <label className="container">
            <input ref={ref} type="checkbox" checked={selected} />
            <span className="checkmark"></span>
          </label>
        </CustomCheckBox>
      </Text>
    </ChatListElementContainer>
  );
};

export default ForwardModalChatElement;
const ChatListElementContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 16px;
  gap: 10px;
  cursor: pointer;
  z-index: 1;
`;
const UserAvatar = styled.div`
  display: flex;
  align-items: center;
  border-radius: 100px;
  border: 1px solid ${colors.purple};
  max-height: 35px;
  max-width: 35px;
  object-fit: contain;
  position: relative;
  min-width: 35px;
  min-height: 35px;
  overflow: hidden;
  justify-content: center;
  svg {
    position: relative;
    width: 100%;
    height: 100%;
  }
  img {
    object-fit: cover;
    position: relative;
    height: 43px;
    width: 43px;
  }
`;
const Text = styled.div`
  display: flex;
  gap: 4px;
  overflow: hidden;
  width: 100%;
  position: relative;
  height: 100%;
`;
const PhoneNumber = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(54, 62, 81, 0.5);
  width: 100%;
  height: 100%;
  h1 {
    color: ${colors.white};
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
  }

  h2 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: ${colors.purple};
  }
`;

const CustomCheckBox = styled.div`
  display: block;
  align-items: center;
  z-index: -1;
  .container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-left: 35px;
    margin-bottom: 25px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    z-index: 0;
    top: auto;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid rgba(91, 91, 93, 1);
    border-radius: 50%;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: rgba(112, 105, 253, 1);
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 7px;
    top: 3.5px;
    width: 3px;
    height: 7px;
    border: solid white;
    border-width: 0 1.5px 1.5px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
