import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as CloseIcon } from "../../Assets/icons/closeIcon.svg";
import { ReactComponent as SearchIcon } from "../../Assets/icons/searchIcon.svg";
import { colors } from '../../Assets/styles/colors';
import { localizationStrings } from '../../Localization/Localization';



const CulturalBgInfoModal = ({ openModal }) => {


    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Escape') {
                event.preventDefault();
                // 👇️ your logic here
                openModal();
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);
    return (
        <FullScreenItem>
            <Container>
                <Header>
                    <h5>{localizationStrings.onboardingPage.cultural_bg}</h5>
                </Header>
                <Description>
                    <p>{localizationStrings.onboardingPage.cultural_bg_intro}</p>
                    <ul style={{ padding: "0 10px 0 20px" }}>
                        <li>{localizationStrings.onboardingPage.cultural_bg_item1}</li>
                        <li>{localizationStrings.onboardingPage.cultural_bg_item2}</li>
                        <li>{localizationStrings.onboardingPage.cultural_bg_item3}</li>
                        <li>{localizationStrings.onboardingPage.cultural_bg_item4}</li>
                    </ul>
                    <p> {localizationStrings.onboardingPage.cultural_bg_info}</p>
                </Description>

                <CloseBtn onClick={openModal}>
                    {localizationStrings.onboardingPage.close}
                </CloseBtn>
            </Container>
        </FullScreenItem>

    )
}

export default CulturalBgInfoModal

const FullScreenItem = styled.div`
position:absolute;
top:0;
left:0;
right:0;
bottom:0;
background:rgba(0,0,0,0.3);
width:100%;
height:100%;
display:flex;
padding:20px;
justify-content:center;
align-items:center;
z-index:3;
overflow:hidden;


`
const Container = styled.div`
display:flex;
flex-direction:column;
padding:25px;
max-height:100%;
width:400px;
background:${colors.newChatModalBg};
gap:20px;
border-radius:10px;
overflow:auto;
`

const Header = styled.div`
display:flex;
align-items:center;
justify-content:center;

h5{
font-size:24px;
font-weight:500;
line-height:28px;
color:${colors.listColor};
}
`

const CloseBtn = styled.button`
display:flex;
align-items:center;
background:none;
border:none;
cursor:pointer;
color:${colors.purple};
font-size:20px;
lin-height:16px;
margin-left:auto;
margin-right:auto;
`

const Description = styled.div`
font-size:16px;
font-weight:400;
line-height:18px;
white-space:pre-wrap;
color:${colors.listColor};
gap:15px;
display:flex;
flex-direction:column;
`