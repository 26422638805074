import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import SpeechMessage from './SpeechMessage'
import { InfiniteScroll } from 'stream-chat-react'
import { useAuthService } from '../../Context/AuthContext'
import { ThreeDots } from 'react-loader-spinner'
import { AUTO, MANUAL } from '../../Constants/constantValues'
import { localizationStrings } from '../../Localization/Localization'

const SpeechContainer = ({ speechRecongnitionMessages, micEnabled, toggle, setToggle, manualRecordingActive }) => {
    const { user, callTranslationsLanguages } = useAuthService()

    let foundLanguage = callTranslationsLanguages?.find((item) => item.country_code === user.call_language);

    return (
        <Container id="speechContainer">
            <ToggleContainer>
                <ToggleButton $active={toggle === AUTO} onClick={(e) => setToggle(AUTO)}>Auto</ToggleButton>
                <ToggleButton $active={toggle === MANUAL} onClick={(e) => setToggle(MANUAL)}>Manual</ToggleButton>
            </ToggleContainer>
            <InfiniteScroll
                dataLength={speechRecongnitionMessages.length}
                scrollableTarget="speechContainer"
                style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
                {speechRecongnitionMessages.length === 0 &&
                    <InfoBlock>
                        <p>{localizationStrings.speech_container.note} {foundLanguage?.name} ({foundLanguage?.translation}) {localizationStrings.speech_container.note2}</p>
                    </InfoBlock>
                }
                <DotsWrapper>

                    {(!micEnabled && (toggle === AUTO || manualRecordingActive)) &&
                        <ListenerWrapper>
                            <ThreeDots visible={true} height={"15"} width={"40"} color={colors.purple} />
                        </ListenerWrapper>

                    }
                </DotsWrapper>
                {speechRecongnitionMessages?.map((message, index) => (
                    <SpeechMessage
                        faded={index !== 0}
                        key={message.id}
                        message={message}
                        ownMessage={message.user_id === user.id}
                    />
                ))}
            </InfiniteScroll>
        </Container>
    )
}

export default SpeechContainer

const Container = styled.div`
display:flex;
flex-direction:column;
background:${colors.chatBg};
width:100%;
height:100%;
gap:20px;
padding:20px;
overflow:auto;
`
const InfoBlock = styled.div`

p{
font-size:16px;
color:${colors.chatElementColor};
}

`
const ListenerWrapper = styled.div`
display:flex;
align-items:center;
justify-content:center;
border-radius:16px;
background:${colors.chatElementBgSelected};
margin-left:auto;
min-height:30px;
min-width:70px;
`
const ToggleContainer = styled.div`
display:flex;
align-items:center;
border:1px solid ${colors.purple};
padding:3px;
border-radius:16px;
gap:10px;
width:fit-content;
margin-left:auto;
margin-right:auto;
`

const ToggleButton = styled.button`
display:flex;
align-items:center;
justify-content:center;
border-radius:0;
border:none;
padding:5px 10px;
font-size:14px;
font-weight:500;
line-height:18px;
color:${colors.white};
background:none;
cursor:pointer;
background:${props => props.$active ? colors.chaetElementBgSelected : "unset"};
&:first-child{
border-radius:16px 0 0 16px;
}
&:last-child{
    border-radius:0 16px 16px 0;
}
min-width:90px;
`
const DotsWrapper = styled.div

    `
display:flex;
min-height:30px;

`