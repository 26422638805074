import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import "../../Assets/styles/customPhoneSelect.css";
import "react-phone-number-input/style.css";
import { colors } from "../../Assets/styles/colors";
import { localizationStrings } from "../../Localization/Localization";
import { ReactComponent as CopyIcon } from "../../Assets/icons/context-menu-icons/CopyIcon.svg";

import CloseQcConfirmation from "./CloseQcConfirmation.js";



const QcChatDetailsView = ({
    conversationData,
    handleShareBtn,
    handleCloseChatBtn
}) => {
    const [closeConfirmOpen, setCloseConfirmOpen] = useState(false)
    return (
        <AddNewGroupContainer>
            <Group>
                <TitleWrapper>
                    <h1>{localizationStrings.new_chat.name}</h1>
                </TitleWrapper>
                <OptionWrapper>
                    <CustomNameInput
                        readOnly
                        value={conversationData.quick_chat_name}
                        name="qcName"
                        maxLength={100}
                    ></CustomNameInput>
                </OptionWrapper>


            </Group>
            <Group>
                <SubtitleWrapper>
                    <p>{localizationStrings.quick_chat.caution}</p>
                </SubtitleWrapper>
            </Group>
            <Group>
                <TitleWrapper>
                    <h1>{localizationStrings.chat_details.generated_link}</h1>
                </TitleWrapper>
                <InputCopyWrapper>
                    <CustomReadOnlyInput
                        readOnly
                        value={conversationData.url}
                    />

                </InputCopyWrapper>

            </Group>

            <Group>
                <Line>
                    <ShareBtn onClick={() => handleShareBtn(conversationData.url)}>
                        {localizationStrings.quick_chat.copy_link}
                    </ShareBtn>
                </Line>
                <Line>
                    <CloseChatBtn onClick={() => setCloseConfirmOpen(true)}>
                        {localizationStrings.quick_chat.close_chat}
                    </CloseChatBtn>
                </Line>
            </Group>

            <CloseQcConfirmation
                open={closeConfirmOpen}
                handleClose={() => setCloseConfirmOpen(false)}
                handleRemove={() => handleCloseChatBtn(conversationData.id)}
            />
        </AddNewGroupContainer>
    )

};

export default QcChatDetailsView;

const AddNewGroupContainer = styled.div`
  background: ${colors.newChatModalBg};
  display: flex;
  flex-direction: column;
  padding: 24px 21px;
  gap: 25px;
  z-index: 2;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  h1 {
    color: ${colors.optionsGroupTitle};
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    text-transform: uppercase;
  }
`;
const SubtitleWrapper = styled.div`
display: flex;
justify-content: space-between;
p {
  color: ${colors.chatElementColor};
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;

}
`

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 16px;
  background: ${colors.newChatModalInputBg};
  border-radius: 10px;
`;

const CustomInput = styled.input`
  background: none;
  border: none;
  color: ${colors.white};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  &:focus-visible {
    outline: none;
  }
`;

const CustomNameInput = styled(CustomInput)`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  padding:10px 0;
`;

const InputCopyWrapper = styled.div`
display:flex;
align-items:center;
border:1px solid ${colors.chatElementColor};
border-radius:10px;
overflow:hidden;
color:white;
width:100%;
`
const CopyBtn = styled.button`
display:flex;
align-items:center;
justify-content:center;
border:none;
background:none;
color:white;
padding:13px 16px;
font-size: 14px;
font-weight: 400;
cursor:pointer;
color:${colors.purple};
`
const CustomReadOnlyInput = styled.input`
display:flex;
padding:13px 16px;
background:none;
outline:none;
color:white;
font-size: 16px;
font-weight: 400;
line-height: 22px;
text-align: left;
border:none;
border-right:1px solid ${colors.chatElementColor};
width:100%;

`
const ShareBtn = styled.button`
display:flex;
justify-content:center;
align-items:center;
width:100%;
background:${colors.purple};
color:${colors.white};
border-radius:10px;
border:1px solid ${colors.purple};

padding:14px;
cursor:pointer;
`
const CloseChatBtn = styled(ShareBtn)`
border:1px solid ${colors.errorColor};
color:${colors.errorColor};
background:none;
`
const Line = styled.div`
display:flex;
gap:20px;
align-items:center;
`