import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors';
import { ReactComponent as AvatarUnset } from '../../Assets/icons/unsetAvatar.svg'
import { useExpanded } from '../../hooks/useExpanded';
import { findTranslatedTextForUser } from '../../Helpers/chatHelper/ChatHelper';
import { localizationStrings } from '../../Localization/Localization';


const MessageInfoContactElement = ({ item, deliveredInfo, readInfo, style, read, currentMessage }) => {

    const [expanded, expand] = useExpanded();
    const translatedMessage = findTranslatedTextForUser(currentMessage.message_translations, item);

    return (
        <>
            <Contact style={style} onClick={() => { expand() }}>
                <ContactAvatar>
                    {item.profile_picture ? <img src={item.profile_picture} alt='avatar' /> : <AvatarUnset />}
                </ContactAvatar>
                <ContactNameWrapper>
                    <ContactName>{item.name}</ContactName>
                    {!read &&
                        <DeliveredInfo>
                            <p>  {deliveredInfo}</p>
                        </DeliveredInfo>}
                    {read && readInfo &&
                        <DeliveredInfo>
                            <p>  {readInfo}</p>
                        </DeliveredInfo>
                    }
                </ContactNameWrapper>
            </Contact>
            {expanded &&
                <InfoWrapper>
                    <Line>
                        <DeliveredInfo style={{ marginLeft: "inherit" }}>
                            <h2>
                                {localizationStrings.delivered}
                            </h2>
                        </DeliveredInfo>
                        <DeliveredInfoDetailed>
                            <p>
                                {deliveredInfo}
                            </p>
                        </DeliveredInfoDetailed>
                    </Line>
                    {readInfo &&
                        <Line>
                            <DeliveredInfo style={{ marginLeft: "inherit" }}>
                                <h2>{localizationStrings.read}</h2>
                            </DeliveredInfo>
                            <DeliveredInfoDetailed>
                                <p>
                                    {readInfo}</p>
                            </DeliveredInfoDetailed>
                        </Line>
                    }
                    <Group>
                        <DeliveredInfo style={{ marginLeft: "inherit" }}>
                            <h2> {localizationStrings.translated_message}</h2>
                        </DeliveredInfo>
                        <DeliveredInfoDetailed style={{ marginLeft: "inherit" }}>
                            <p style={{ marginLeft: "inherit", textAlign: "inherit" }}>
                                {translatedMessage?.translation}
                            </p>
                        </DeliveredInfoDetailed>
                    </Group>
                </InfoWrapper>
            }
        </>
    )
}

export default MessageInfoContactElement


const Contact = styled.div`
display:flex;
align-items:center;
gap:10px;
overflow:hidden;
cursor:pointer;
width:100%;
`
const InfoWrapper = styled.div`
display:flex;
flex-direction:column;
width:100%;
`
const ContactNameWrapper = styled.div`
display:flex;
align-items:center;
border-bottom:1px solid rgba(54, 62, 81, 0.5);
width:100%;
overflow:hidden;
gap:10px;
`
const Line = styled.div`
display:flex;
align-items:center;
gap:10px;
padding:5px 0;
border-bottom:1px solid rgba(54, 62, 81, 0.5);
width:100%;
`
const ContactName = styled.p`
text-overflow:ellipsis;
overflow:hidden;
white-space:pre;
font-size: 16px;
font-weight: 400;
line-height: 35px;
text-align: left;
color:${colors.white};
`
const RemoveButton = styled.button`
background:none;
border:none;
display:flex;
align-items:center;
cursor:pointer;
svg{
    height:24px;
}
`
const ContactAvatar = styled.div`
  display: flex;
  height:35px;
  width:35px;
  max-width: 35px;
  min-height: 35px;
  min-width: 35px;
  max-height: 35px;
  overflow: hidden;
  border-radius: 100%;
//   height: 100%;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  svg{
     object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;
const DeliveredInfo = styled.div`
display:flex;
   margin-left:auto;

p{
min-width:120px;
  color: ${colors.chatElementColor};
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
white-space:wrap;
margin-left:auto;
text-align:end;
}
h2{
 color: ${colors.chatElementColor};
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
white-space:wrap;
width:fit-content;
}
  
`
const DeliveredInfoDetailed = styled(DeliveredInfo)`
    color: ${colors.white};
    
`
const ActionWrapper = styled.div`
display:flex;
align-items:center;
justify-content:center;
margin-right:16px;
margin-left:auto;
`
const Group = styled.div`
display:flex;
flex-direction:column;
gap:10px;
padding:5px 0;
border-bottom:1px solid rgba(54, 62, 81, 0.5);
width:100%;
overflow:hidden;
`