import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import React from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";
import { ReactComponent as AvatarUnset } from "../../Assets/icons/unsetAvatar.svg";
import { localizationStrings } from "../../Localization/Localization";

const RequestTranslationModal = ({
  open,
  handleClose,
  callDetails,
  handleEnable,
}) => {
  const details = callDetails?.details;

  const diplayAvatar = (details) => {
    let display = <AvatarUnset />;

    if (details && details.created_by && details.created_by.profile_picture) {
      display = <img src={details.created_by.profile_picture} alt="avatar" />;
    }

    return display;
  };

  return (
    <CustomDialog
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <CustomDialogContent>
        <UserWrapper>
          <CallerName>
            {localizationStrings.request_translation_modal.title}
          </CallerName>
        </UserWrapper>

        <Details>
          {localizationStrings.request_translation_modal.request}
        </Details>
      </CustomDialogContent>

      <DialogActionsCustom>
        <Button onClick={handleClose} className="btn closeBtn">
          {localizationStrings.request_translation_modal.deny}
        </Button>
        <Button className="btn answerBtn" onClick={handleEnable} autoFocus>
          {localizationStrings.request_translation_modal.enable}
        </Button>
      </DialogActionsCustom>
    </CustomDialog>
  );
};

export default RequestTranslationModal;

const CustomDialog = styled(Dialog)`
  .MuiDialog-paper {
    border-radius: 18px;
    background: ${colors.background};
    min-width: 350px;
    max-width: 350px;
  }

  .btn {
    font-size: 16px;
    font-weight: 500;
    border-radius: 6px;
    min-width: 150px;
    text-transform: none !important;
  }

  .closeBtn {
    color: ${colors.white};
    background: ${colors.errorColor};
    &:hover {
      background: red !important;
    }
  }

  .answerBtn {
    color: ${colors.white};
    background: ${colors.validGreen};
    &:hover {
      background: green !important;
    }
  }
`;
const CustomDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-conten: center;
  padding: 20px 20px 0 20px !important;
`;
const Details = styled.h1`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: ${colors.chatElementColor};
`;
const CallerName = styled.h1`
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  color: ${colors.white};
`;

const DialogActionsCustom = styled(DialogActions)`
  display: flex;
  justify-content: center !important;
  gap: 20px;
  webkit-justify-content: center;
  padding: 20px !important;
`;

const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ContactAvatar = styled.div`
  display: flex;
  height: 45px;
  width: 45px;
  max-width: 45px;
  min-height: 45px;
  min-width: 45px;
  max-height: 45px;
  overflow: hidden;
  border-radius: 100%;
  //   height: 100%;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  svg {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;
