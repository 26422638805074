import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";
import { localizationStrings } from "../../Localization/Localization";
import { ReactComponent as PlusIcon } from "../../Assets/icons/PlusIcon.svg";
import { ReactComponent as AvatarUnset } from "../../Assets/icons/unsetAvatar.svg";
import { ReactComponent as AvatarPencil } from "../../Assets/icons/avatarPencil.svg";
import { ReactComponent as LeaveGroupIcon } from "../../Assets/icons/logoutIcon.svg";
import { ADD_MEMBERS } from "../NewGroup/AddNewGroup";
import { useAuthService } from "../../Context/AuthContext";
import ContactElementChatDetails from "../Chat/ChatDetailsContactElement";
import { Item, Menu, Submenu, useContextMenu } from "react-contexify";
import DeleteConfirmationChild from "../GenericComponents/DeleteConfirmationChild";

const CustomItem = ({ text, icon, redButton }) => {
  return (
    <CustomItemContainer redButton={redButton}>
      <p>{text}</p>
      {icon}
    </CustomItemContainer>
  );
};

const buttonList = [
  {
    id: "make_admin",
    text: localizationStrings.chat_details.make_admin,

  },
  {
    id: "revoke_admin",
    text: localizationStrings.chat_details.revoke_admin,
    redButton: true,
    withConfirmation: true
  },

  {
    id: "remove_user",
    text: localizationStrings.chat_details.remove_user,
    redButton: true,
    withConfirmation: true

  },


]




const GroupSelectedMembers = ({
  inputValues,
  setOption,
  previewAvatar,
  handleInputChange,
  handleOpenAvatar,
  handleAvatarChange,
  profileAvatarRef,
  selectedContacts,
  groupData,
  handleRemoveMember,
  initialContacts,
  handleMakeAdmin,
  handleRevokeAdmin
}) => {

  const { user } = useAuthService();
  const [croupMembersNumber, setGroupMembersNumber] = useState(0);
  const [removeUserConfirmation, setRemoveUserConfirmation] = useState(false);
  const [revokeAdminConfirmation, setRevokeAdminConfirmation] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [isCurrentUserAdmin, setIsCurrentUserAdmin] = useState(false)

  const calculateGroupMembers = () => {
    let found = groupData.other_participants.find(
      (item) => item.id === user.id
    );
    var number = 0;
    if (!found) {
      number = groupData.other_participants.length + 1;
    } else {
      number = groupData.other_participants.length;
    }
    setGroupMembersNumber(number);
  };

  const MENU_ID = "groupDetailsMenu-id";

  const { show } = useContextMenu({
    id: MENU_ID,
  });

  function displayMenu(e, user) {
    // put whatever custom logic you need
    // you can even decide to not display the Menu
    setSelectedMember(user);
    show({
      event: e,
    });
  }

  function handleItemClick({ id }) {
    switch (id) {
      case "make_admin":
        handleMakeAdmin(selectedMember)
        break;
      case "revoke_admin":
        setRevokeAdminConfirmation(true)
        break;
      case "remove_user":
        setRemoveUserConfirmation(true)
        break;
      default:
        break;
    }
  }

  const checkIfUserIsAdmin = (contact) => {
    if (!contact) {
      return
    }

    let isAdmin = groupData?.admin_ids?.find((item) => item === contact.id)

    return isAdmin
  }

  const filterButtons = (buttons) => {
    let availableOptions = buttons;
    if (!user || !selectedMember) {
      return availableOptions
    }

    const userIsAdmin = checkIfUserIsAdmin(selectedMember);

    if (selectedMember.id === user.id) {
      availableOptions = availableOptions.filter((item) => item.id !== "remove_user")
    }
    if (!userIsAdmin) {
      availableOptions = availableOptions.filter((item) => item.id !== "revoke_admin")
    }
    if (userIsAdmin) {
      availableOptions = availableOptions.filter((item) => item.id !== "make_admin")
    }

    return availableOptions
  }

  const handleLeaveGroup = (user) => {
    setSelectedMember(user);
    setRemoveUserConfirmation(true)
  }

  useEffect(() => {
    if (checkIfUserIsAdmin(user)) {
      setIsCurrentUserAdmin(true)
    } else {
      setIsCurrentUserAdmin(false)
    }
  }, [groupData])

  useEffect(() => {
    calculateGroupMembers();
  }, [groupData.other_participants]);

  return (
    <>
      <Group>
        <TitleWrapper>
          <h1>{localizationStrings.new_chat.group_details}</h1>
        </TitleWrapper>
        <OptionWrapper>
          <ProfileDetails>
            <UserWrapper>
              <input
                type="file"
                hidden
                accept=".png, .jpg, .jpeg "
                ref={profileAvatarRef}
                onChange={(e) => {
                  handleAvatarChange(e)
                }}
              />
              <UserAvatarWrapper>
                <UserAvatar onClick={() => {
                  handleOpenAvatar()
                }}>
                  {previewAvatar ? (
                    <img src={previewAvatar} alt="avatar" />
                  ) : (
                    <AvatarUnset />
                  )}
                </UserAvatar>
                <PencilWrapper>
                  <AvatarPencil />
                </PencilWrapper>
              </UserAvatarWrapper>
              <CustomNameInput
                value={inputValues.groupName}
                onChange={(e) => handleInputChange(e)}
                placeholder={localizationStrings.new_chat.enter_group_name}
                name="groupName"
                maxLength={100}
              ></CustomNameInput>
            </UserWrapper>
          </ProfileDetails>
        </OptionWrapper>
      </Group>
      <Separator />
      {checkIfUserIsAdmin(user) &&
        <Group>
          <TitleWrapper>
            <h1>{localizationStrings.new_chat.add_members}</h1>
          </TitleWrapper>
          <ChatOption style={{ flexDirection: "column" }}>
            <AddMembersButton onClick={() => setOption(ADD_MEMBERS)}>
              <PlusIcon />  {localizationStrings.new_chat.add_members}
            </AddMembersButton>
          </ChatOption>
        </Group>

      }
      <Group>

        <ChatOption style={{ flexDirection: "column" }}>
          <DeleteConfirmationChild>
            <RemoveMemberButton onClick={(e) => handleLeaveGroup(user)}>
              <LeaveGroupIcon /> {localizationStrings.chat_details.leave_group}
            </RemoveMemberButton>
          </DeleteConfirmationChild>
        </ChatOption>
      </Group>
      {initialContacts?.length > 0 && (
        <Group>
          <TitleWrapper>
            <h1>
              {croupMembersNumber} {localizationStrings.new_chat.members}
            </h1>
          </TitleWrapper>
          <MebersList>
            {initialContacts?.map((contact, index) => (
              <ContactElementChatDetails
                key={index}
                item={contact}
                variant={contact.id === user.id ? "me" : ""}
                displayMenu={displayMenu}
                isCurrentUserAdmin={isCurrentUserAdmin}
                isUserAdmin={checkIfUserIsAdmin(contact)}
              />
            ))}
          </MebersList>

          <StyledMenu id={MENU_ID}>
            {filterButtons(buttonList).map((item, index) => {
              if (item.withConfirmation) {
                return (
                  <Item onClick={handleItemClick} id={item.id} key={index}>
                    <CustomItem onClick={handleItemClick} text={item.text} redButton={item.redButton} />
                  </Item>
                )
              } else {
                return (
                  <Item onClick={handleItemClick} id={item.id} key={index}>
                    <CustomItem onClick={handleItemClick} text={item.text} redButton={item.redButton} />
                  </Item>
                )
              }
            }
            )}
          </StyledMenu>

          <DeleteConfirmationChild
            setOpen={setRemoveUserConfirmation}
            open={removeUserConfirmation}
            action={handleRemoveMember}
            item={selectedMember}
            variant={selectedMember?.id === user.id ? "me" : ""}
          />

          <DeleteConfirmationChild
            setOpen={setRevokeAdminConfirmation}
            open={revokeAdminConfirmation}
            action={handleRevokeAdmin}
            item={selectedMember}
            variant={"revoke_admin"}
          />


        </Group>
      )}
    </>
  );
};

export default GroupSelectedMembers;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  h1 {
    color: ${colors.optionsGroupTitle};
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    text-transform: uppercase;
  }
`;
const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  background: ${colors.newChatModalInputBg};
  border-radius: 10px;
`;

const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserAvatar = styled.div`
  display: flex;
  align-items: center;
  border-radius: 100px;
  border: 1px solid ${colors.purple};
  cursor: pointer;
  max-height: 42px;
  max-width: 42px;
  min-width: 42px;
  min-height: 42px;
  position: relative;
  overflow: hidden;
  object-fit: contain;
  svg {
    position: relative;
    width: 100%;
    height: 100%;
  }
  img {
    object-fit: cover;
    position: relative;
    height: 43px;
    width: 43px;
  }
`;

const UserWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  border-bottom: 1px solid #363e5180;
  padding: 8px 0;
  h1 {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    color: ${colors.white};
  }
`;

const CustomInput = styled.input`
  background: none;
  border: none;

  color: ${colors.white};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  &:focus-visible {
    outline: none;
  }
`;

const ChatOption = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  border-bottom: 1px solid #363e5180;
  &:last-child {
    border: none;
  }
  h1 {
    min-width: 75px;
    color: #ffffff99;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.40799999237060547px;
  }
  h2 {
    text-transform: capitalize;
    color: ${colors.white};
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.40799999237060547px;
  }
`;

const CustomNameInput = styled(CustomInput)`
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
`;

const Separator = styled.div`
  background: rgba(54, 62, 81, 0.5);
  height: 1px;
  width: 100%;
`;

const AddMembersButton = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  color: ${colors.purple};
  border: none;
  border-radius: 10px;
  background: ${colors.newChatModalInputBg};
  padding: 14px 16px;
  cursor: pointer;
`;
const RemoveMemberButton = styled(AddMembersButton)`
color:${colors.errorColor};
svg{
color:${colors.errorColor};
}
`

const UserAvatarWrapper = styled.div`
  position: relative;
`;
const PencilWrapper = styled.div`
  position: absolute;
  bottom: -5px;
  right: 0;
`;

const MebersList = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.newChatModalInputBg};
  border-radius: 10px;
  padding: 10px 16px;
  gap: 8px;
`;

const CustomItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: ${(props) => (props.redButton ? colors.errorColor : "white")};
  svg {
    height: 20px;
    width: 20px;
  }
`;

const StyledMenu = styled(Menu).attrs({
  // custom props
})`
  background: rgba(50, 52, 68, 1);
  color: white;
  padding: 0;
  border-radius: 12px;

  .contexify_item {
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.3);
    padding: 6px 10px;
    &:last-child {
      border-bottom: none;
    }
  }
  .contexify_itemContent {
    color: ${colors.errorColor};
  }
  .contexify_item:not(.contexify_item-disabled):hover > .contexify_itemContent,
  .contexify_item:not(.contexify_item-disabled):focus > .contexify_itemContent {
    color: inherit;
    background-color: rgba(255, 255, 255, 0.1);
  }
`;
