import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import "../../Assets/styles/customPhoneSelect.css";
import "react-phone-number-input/style.css";
import { colors } from "../../Assets/styles/colors";
import { ReactComponent as AvatarUnset } from '../../Assets/icons/unsetAvatar.svg'
import { ReactComponent as AvatarPencil } from "../../Assets/icons/avatarPencil.svg";
import { localizationStrings } from "../../Localization/Localization";
import PhoneInput from "react-phone-number-input";
import DeleteConfirmationChild from "../GenericComponents/DeleteConfirmationChild";


const ChatDetailsView = ({
  conversationData,
  handleBlockContact,
  handleUnblockContact
}) => {


  const profileAvatarRef = useRef();
  const [previewAvatar, setPreviewAvatar] = useState(conversationData.profile_picture);
  const [avatar, setAvatar] = useState(conversationData.profile_picture);
  const [name, setName] = useState(conversationData.name);
  const [about, setAbout] = useState(conversationData.about);
  const [phone, setPhone] = useState(conversationData?.phone_number);

  const [openUnblockModal, setOpenUnblockModal] = useState(false)
  const [openBlockModal, setOpenBlockModal] = useState(false)


  const handleAvatarChange = (e) => {
    setAvatar(e.target.files[0])
    setPreviewAvatar(URL.createObjectURL(e.target.files[0]));
  };

  useEffect(() => {
  }, [conversationData])

  return (
    <AddNewGroupContainer>
      <Group>
        <TitleWrapper>
          <h1>{localizationStrings.chat_details.user_details}</h1>
        </TitleWrapper>

        <OptionWrapper>
          <ProfileDetails>
            <UserWrapper>
              <input
                type="file"
                hidden
                accept=".png, .jpg, .jpeg "
                ref={profileAvatarRef}
                onChange={(e) => handleAvatarChange(e)}
              />
              <UserAvatarWrapper>
                <UserAvatar>
                  {avatar ? <img src={previewAvatar} alt='avatar' /> : <AvatarUnset />}
                </UserAvatar>
                <PencilWrapper>
                </PencilWrapper>
              </UserAvatarWrapper>
              <CustomNameInput
                value={name}
                readOnly
              ></CustomNameInput>
            </UserWrapper>
            {about && about.length > 1 &&
              <AboutWrapper>
                <CustomNameInput
                  value={about}
                  readOnly
                ></CustomNameInput>
              </AboutWrapper>}

          </ProfileDetails>
        </OptionWrapper>
      </Group>

      <Group>
        <TitleWrapper>
          <h1>{localizationStrings.chat_details.account_details}</h1>
        </TitleWrapper>
        <OptionWrapper>
          {conversationData.sso ?
            <>
              <OptionLabel>
                <p>{localizationStrings.profile.email}</p>
              </OptionLabel>
              <AboutWrapper>
                <CustomInput
                  value={conversationData.email}
                  readOnly
                ></CustomInput>
              </AboutWrapper>

            </> :


            <>


              <OptionLabel>
                <p>{localizationStrings.profile.phone_number}</p>
              </OptionLabel>
              <PhoneNumberWrapper>
                <CustomPhoneWrapper>
                  <PhoneInput
                    readOnly
                    international
                    defaultCountry={"RO"}
                    name="phoneNumber"
                    placeholder={localizationStrings.profile.phone_number}
                    value={phone}
                    onChange={(e) => setPhone(e)}

                  />
                </CustomPhoneWrapper>
              </PhoneNumberWrapper>
            </>}


        </OptionWrapper>
      </Group>
      <Group>
        <OptionWrapper>
          {conversationData.blocked
            ?
            <DeleteConfirmationChild
              open={openUnblockModal}
              setOpen={setOpenUnblockModal}
              variant={"unblock_contact"}
              action={handleUnblockContact}
              item={conversationData}

            >
              <TextButton onClick={() => setOpenUnblockModal(true)}>
                {localizationStrings.block_contacts.unblock}
              </TextButton>
            </DeleteConfirmationChild>

            :
            <DeleteConfirmationChild
              open={openBlockModal}
              setOpen={setOpenBlockModal}
              variant={"block_contact"}
              action={handleBlockContact}
              item={conversationData}

            >
              <TextButton onClick={() => setOpenBlockModal(true)}>
                {localizationStrings.block_contacts.block}
              </TextButton>
            </DeleteConfirmationChild>



          }

        </OptionWrapper>
      </Group>

    </AddNewGroupContainer>
  )

};

export default ChatDetailsView;

const AddNewGroupContainer = styled.div`
  background: ${colors.newChatModalBg};
  display: flex;
  flex-direction: column;
  padding: 24px 21px;
  gap: 25px;
  z-index: 2;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  h1 {
    color: ${colors.optionsGroupTitle};
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    text-transform: uppercase;
  }
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 16px;
  background: ${colors.newChatModalInputBg};
  border-radius: 10px;
`;

const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  border: 1px solid ${colors.purple};
  cursor: pointer;
  max-height: 42px;
  max-width: 42px;
  min-width: 42px;
  min-height: 42px;
  object-fit: contain;
  position: relative;
  overflow: hidden;
   svg {
    position: relative;
    width: 100%;
    height: 100%;
  }
  img{
    object-fit:cover;
 position: relative;
       height:43px;
    width:43px;
`;

const UserWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  border-bottom: 1px solid #363e5180;
  padding: 8px 0;
  h1 {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    color: ${colors.white};
  }
`;
const AboutWrapper = styled.div`
display:flex;
padding:10px 0;
`
const CustomInput = styled.input`
  background: none;
  border: none;
  color: ${colors.white};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  width:100%;
  &:focus-visible {
    outline: none;
  }
`;

const CustomNameInput = styled(CustomInput)`
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
`;

const Separator = styled.div`
  background: rgba(54, 62, 81, 0.5);
  height: 1px;
  width: 100%;
`;


const UserAvatarWrapper = styled.div`
  position: relative;
`;

const PencilWrapper = styled.div`
  position: absolute;
  bottom: -5px;
  right: 0;
`;

const OptionLabel = styled.div`
  display: flex;
  justify-content: space-between;
  
  p {
    color: ${colors.chatElementColor};
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
  }
`
const CustomPhoneWrapper = styled.div`
  background: ${colors.newChatModalInputBg};
  //padding:0 16px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  input {
    padding: 14px 16px;
    color: ${colors.white};
    background: ${colors.newChatModalInputBg};
    border-radius: 10px;
    border: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    &:focus {
      outline: none;
    }
  }
`;
const PhoneNumberWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TextButton = styled.button`
border:none;
height:30px;
background:none;
font-size: 16px;
font-weight: 400;
line-height: 22px;
color:${colors.errorColor};
cursor:pointer;
`