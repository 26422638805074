import { createContext, useContext, useEffect, useRef, useState } from "react";
import Grovs from "grovs";
import LocalStorage from "../Helpers/LocalStorage";

export const LinksquaredContext = createContext({
  linksquared: [],
  linksquaredUnread: [],
  getNumberOfUnreadLinksquaredMessages: () => {},
  markLinksquaredMessageAsRead: () => {},
  setUserDetails: () => {},
  generateLink: () => {},
  getAllReceivedData: () => {},
});

const LinksquaredContextProvider = ({ children }) => {
  const [linksquaredUnread, setLinksquaredUnred] = useState(0);

  const linksquared = useRef();

  const getNumberOfUnreadLinksquaredMessages = () => {
    linksquared.current.getNumberOfUnreadMessages(
      (response) => {
        setLinksquaredUnred(response.number_of_unread_notifications);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const markLinksquaredMessageAsRead = (message) => {
    linksquared.current.markMessageAsRead(
      message,
      () => {
        getNumberOfUnreadLinksquaredMessages();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const setUserDetails = (user) => {
    linksquared.current.setUserIdentifier(user.id);
    linksquared.current.setUserAttributes({ name: user.name });
  };

  const getAllReceivedData = (dataCallback) => {
    const data = linksquared.current.getAllReceivedData();
    dataCallback(data);
  };

  const getInvitedBy = () => {
    const dataArray = linksquared.current.getAllReceivedData();

    dataArray.forEach((dict) => {
      if (dict["invited_by"] != null) {
        LocalStorage.setInvitedBy(dict["invited_by"]);
      }
    });

    return LocalStorage.getInvitedBy();
  };

  const generateLink = (title, subtitle, user, success, error) => {
    linksquared.current.createLink(
      title,
      subtitle,
      null,
      { invited_by: `${user.id}` },
      success,
      error
    );
  };

  useEffect(() => {
    linksquared.current = new Grovs(
      "emotii_2fb260c812b350d41e4268f59362ad13f5c4a84011ded882973425c5a26a5026",
      false,
      (data) => {
        console.log("Link data:", data);
      }
    );
    linksquared.current.start();
    getNumberOfUnreadLinksquaredMessages();
  }, []);

  return (
    <LinksquaredContext.Provider
      value={{
        linksquared,
        linksquaredUnread,
        getNumberOfUnreadLinksquaredMessages,
        markLinksquaredMessageAsRead,
        setUserDetails,
        generateLink,
        getAllReceivedData,
        getInvitedBy,
      }}
    >
      {children}
    </LinksquaredContext.Provider>
  );
};
export const useLinksquaredService = () => useContext(LinksquaredContext);

export default LinksquaredContextProvider;
