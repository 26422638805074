import React, { useRef } from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { ReactComponent as Unfold } from "../../Assets/icons/Unfold_More.svg"
import { ReactComponent as DownIcon } from "../../Assets/icons/dropDownArrowNoColor.svg"
import { useCallsService } from '../../Context/CallsContext'
import { ReactComponent as Speaker } from "../../Assets/icons/calls/speakerOff.svg"



const ReadOnlyTranslation = ({ fromLanguage, toLanguage, initialText, translatedText }) => {
    const { speakAudio, transcribeAudio } = useCallsService();
    const audioFilesRef = useRef();

    const handleSpeak = (text) => {
        speakAudio(
            text,
            (response) => {
                const apiResponse = response.data;
                const url = URL.createObjectURL(apiResponse);
                audioFilesRef.current = url
                handlePlay(audioFilesRef.current)
            },
            (error) => {
                console.log(error)
            }
        )
    }

    const handlePlay = (audioFile) => {
        if (!audioFile) {
            return
        }
        const audio = new Audio(audioFile);
        audio.play();
        audio.onended = () => {
            audioFilesRef.current = null
        }
    }

    const handleSpeakClick = (text) => {
        handleSpeak(text)
    }


    return (
        <Card>
            <LanguageInput>
                <Line>
                    <LanguageSelector >
                        <LanguageName>
                            {fromLanguage}
                        </LanguageName>
                    </LanguageSelector>
                    <SpeakerWrapper onClick={() => handleSpeakClick(initialText)}>
                        <Speaker />
                    </SpeakerWrapper>

                </Line>
                <LanguageContentInput readOnly value={initialText} />
            </LanguageInput>
            {/* <DownItem>
                <DownIcon />
            </DownItem> */}
            <LanguageInput >
                <Line>
                    <LanguageSelector>
                        <LanguageNameOutput>
                            {toLanguage}
                        </LanguageNameOutput>

                    </LanguageSelector>
                    <SpeakerWrapper onClick={() => handleSpeakClick(translatedText)}>
                        <Speaker />
                    </SpeakerWrapper>
                </Line>
                <LanguageContentOutput readOnly value={translatedText} />
            </LanguageInput>
        </Card>
    )
}

export default ReadOnlyTranslation


const Card = styled.div`
display:flex;
flex-direction:column;
border-radius:12px;
background:${colors.newChatModalBg};
min-width:400px;
width:100%;
min-height:350px;
overflow:hidden;
gap:3px;
position:relative;
`

const LanguageSelector = styled.div`
display:flex;
align-items:center;
gap:5px;
svg{
height:16px;
}
`

const LanguageName = styled.p`
font-size:16px;
font-weight:500;
line-height:24px;
color:${colors.white};
`
const LanguageNameOutput = styled(LanguageName)`
color:${colors.purple};
`

const LanguageInput = styled.div`
display:flex;
flex-direction:column;
padding:20px;
gap:10px;
background:${colors.quickChatClosedBg};
`

const LanguageContentInput = styled.textarea`
min-height:100px;
height:100px;
max-height:100px;
resize:none;
background:transparent;
border:none;
outline:none;
font-size:20px;
font-weight:500;
color:${colors.chatElementColor};
`
const LanguageContentOutput = styled(LanguageContentInput)`
color:${colors.purple};
&::placeholder{
color:${colors.purple};
opacity:0.6;
}
`
const DownItem = styled.div`
display:flex;
align-items:center;
justify-content:center;

background:${colors.background};
border-radius:25px;
height:26px;
width:26px;
position:absolute;
top:calc(50% - 13px);
left:calc(50% - 13px );
svg{
width:12px;
color:${colors.purple};
}
`
const SpeakerWrapper = styled.div`
cursor:pointer;
display:flex;
margin-left:auto;
align-items:center;
justify-content:center;
color:rgba(178,175,255,1);
`
const Line = styled.div`
display:flex;
aling-items:center;
gap:30px;
width:100%;
`
