import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { CALLS_LIST, MESSAGES_LIST, TRANSLATE } from '../../Constants/constantValues'
import { localizationStrings } from '../../Localization/Localization'

const ToggleList = ({ toggle, setToggle, handleTranslateClick }) => {

    return (
        <Container>
            <ToggleContainer>
                <ToggleButton $active={toggle === MESSAGES_LIST} onClick={(e) => setToggle(MESSAGES_LIST)}>{localizationStrings.toggle_list.messages}</ToggleButton>
                <ToggleButton $active={toggle === CALLS_LIST} onClick={(e) => setToggle(CALLS_LIST)}>{localizationStrings.toggle_list.calls}</ToggleButton>
                <ToggleButton $active={toggle === TRANSLATE} onClick={(e) => handleTranslateClick(TRANSLATE)}>{localizationStrings.toggle_list.translate}</ToggleButton>
            </ToggleContainer>


        </Container>
    )
}

export default ToggleList
const Container = styled.div`
display:flex;
align-items:center;
width:100%;
justify-content:center;
background:${colors.userMenuHeaderBg};
padding:0 0 16px 0;
`
const ToggleContainer = styled.div`
display:flex;
align-items:center;
border:1px solid ${colors.purple};
padding:3px;
border-radius:16px;
gap:10px;
`
const ToggleButton = styled.button`
display:flex;
align-items:center;
justify-content:center;
border-radius:0;
border:none;
padding:5px 10px;
font-size:14px;
font-weight:500;
line-height:18px;
color:${colors.white};
background:none;
cursor:pointer;
background:${props => props.$active ? colors.chaetElementBgSelected : "unset"};
&:first-child{
border-radius:16px 0 0 16px;
}
&:last-child{
    border-radius:0 16px 16px 0;
}
min-width:90px;
`