
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { ReactComponent as Unfold } from "../../Assets/icons/Unfold_More.svg"
import { ReactComponent as DownIcon } from "../../Assets/icons/dropDownArrowNoColor.svg"
import { ReactComponent as Speaker } from "../../Assets/icons/calls/speakerOff.svg"
import { ReactComponent as Microphone } from "../../Assets/icons/calls/micOff.svg"
import { ReactComponent as StopRecording } from "../../Assets/icons/calls/stop.svg"

import { useAuthService } from '../../Context/AuthContext'
import LanguageListSelector from '../Auth Components/LanguageListSelector'
import cloneDeep from 'clone-deep'
import { translateMessageAPICall } from '../../API/translate/translateMessage'
import ReadOnlyTranslation from './ReadOnlyTranslation'
import { useCallsService } from '../../Context/CallsContext'
import RecorderService from '../AudioVideoCalls/RecorderService'
import axios from 'axios'

const TranslateComponent = ({ setLoading,
    translationHistory,
    setTranslationHistory,
    fromLanguage,
    setFromLanguage,
    toLanguage,
    setToLanguage,
    from,
    setFrom
}) => {

    const { translationsLanguages, user } = useAuthService();
    const { speakAudio, transcribeAudio } = useCallsService();

    const recorderServiceRef = useRef(new RecorderService());

    // const [translationHistory, setTranslationHistory] = useState([]);
    const toRef = useRef();
    const fromRef = useRef();


    // const [fromLanguage, setFromLanguage] = useState(user.language);
    // const [toLanguage, setToLanguage] = useState(user.language);

    // const [from, setFrom] = useState("");
    const [to, setTo] = useState("");

    const [fromLanguageOpen, setFromLanguageOpen] = useState(false);
    const [toLanguageOpen, setToLanguageOpen] = useState(false);

    const audioFilesRef = useRef();
    const [recordinOn, setRecordingOn] = useState(false)
    const addToHistoryRef = useRef()

    const openFromLanguageList = () => {
        setFromLanguageOpen(!fromLanguageOpen)
    }

    const openToLanguageList = () => {
        setToLanguageOpen(!toLanguageOpen)
    }


    const handleTranscribeAudio = (audio) => {

        return new Promise((resolve, reject) => {
            const newFormData = new FormData();
            newFormData.append("audio_file", audio);
            transcribeAudio(
                newFormData,
                (response) => {
                    const transcribedText = response.data.transcript;
                    resolve(transcribedText);
                },
                (error) => {
                    console.error("Transcription error:", error);
                    reject(error);
                }
            );
        });
    };



    const handleToClick = () => {

        let toCopy = cloneDeep(to);
        let toLangCopy = cloneDeep(toLanguage);

        let fromCopy = cloneDeep(from);
        let fromLangCopy = cloneDeep(fromLanguage);

        setFrom(toCopy);
        setFromLanguage(toLangCopy);

        setTo(fromCopy)
        setToLanguage(fromLangCopy)


        fromRef.current.focus();

    }

    const handleTranslate = (initialLanguage, translateTo, message) => {


        translateMessageAPICall(
            initialLanguage, translateTo, message,
            (response) => {
                const translatedText = response.data.translation.response
                setTo(translatedText);
                if (addToHistoryRef.current) {
                    addToHistoryRef.current = false;
                    if (fromLanguage && toLanguage && from && from.trim().length !== 0)
                        addItemToTranslationHistory(fromLanguage, toLanguage, from, translatedText)
                }

            },
            (error) => {
                console.log(error)

            },
        )
    }

    const handleAddItemToTranslationHistory = (e) => {
        if (e.code === "Enter") {
            e.preventDefault()
        }

        if (e.code === "Enter" && from && from.trim().length !== 0 && to.length !== 0) {
            addItemToTranslationHistory(fromLanguage, toLanguage, from, to)
        }

    }

    const addItemToTranslationHistory = (fromLanguage, toLanguage, from, to) => {

        let newObject = {
            fromLanguage: fromLanguage,
            toLanguage: toLanguage,
            initialText: from.trimStart(),
            translatedText: to
        }

        setTranslationHistory((prev) =>
            [...prev,
                newObject
            ]
        )

        setFrom("");
        setTo("");
    }

    const handleSpeak = (text) => {
        speakAudio(
            text,
            (response) => {
                const apiResponse = response.data;
                const url = URL.createObjectURL(apiResponse);
                audioFilesRef.current = url
                handlePlay(audioFilesRef.current)
            },
            (error) => {
                console.log(error)
            }
        )
    }

    const handlePlay = (audioFile) => {
        if (!audioFile) {
            return
        }
        const audio = new Audio(audioFile);
        audio.play();
        audio.onended = () => {
            audioFilesRef.current = null
        }
    }

    const handleSpeakClick = (text) => {
        handleSpeak(text)
    }

    const startRecorderService = async () => {

        await recorderServiceRef.current.start(async (blob) => {
            const audioFile = blobToFile(blob, "audio.mp3");
            try {
                const transcribed = await handleTranscribeAudio(audioFile);
                addToHistoryRef.current = true
                setFrom(transcribed);
            } catch (error) {
                console.error("Error during transcription:", error);
            }
        });
    };

    const stopRecorderService = async () => {
        await recorderServiceRef.current.stop();
    };

    const blobToFile = (theBlob, fileName) => {
        let file = new File([theBlob], fileName, {
            lastModified: new Date(),
            type: "audio/mp3",
        });
        return file;
    };

    const handleMicrophoneClick = () => {
        startRecorderService();
        setRecordingOn(true)
    }

    const handleStopMicrophoneClick = () => {
        stopRecorderService()
        setRecordingOn(false)
    }

    useEffect(() => {
        if (!addToHistoryRef.current) {
            let time = setTimeout(() => {
                if ((from === "" || from.trim().length === 0) && !recordinOn) {
                    handleTranslate(fromLanguage, toLanguage, "")
                    setTo("");
                    return
                }

                handleTranslate(fromLanguage, toLanguage, from.trimStart())

            }, [250])

            return () => {
                clearInterval(time)
            }

        } else {
            handleTranslate(fromLanguage, toLanguage, from.trimStart())
        }

    }, [fromLanguage, toLanguage, from])

    return (
        <Wrapper>
            {fromLanguageOpen &&
                <LanguageListSelector
                    openLanguageList={openFromLanguageList}
                    valuesList={translationsLanguages}
                    setValue={setFromLanguage}

                />}

            {toLanguageOpen &&
                <LanguageListSelector
                    openLanguageList={openToLanguageList}
                    valuesList={translationsLanguages}
                    setValue={setToLanguage}

                />}
            {recordinOn &&
                <StopRecordingWrapper>
                    <StopRecordingBtn onClick={() => handleStopMicrophoneClick()}>
                        <StopRecording />
                    </StopRecordingBtn>
                </StopRecordingWrapper>
            }
            <Card>

                <LanguageInput>
                    <Line>
                        <LanguageSelector onClick={() => setFromLanguageOpen(true)}>
                            <LanguageName>
                                {fromLanguage}
                            </LanguageName>
                            <Unfold />
                        </LanguageSelector>

                        {from ?
                            <SpeakerWrapper onClick={() => handleSpeakClick(from)}>
                                <Speaker />
                            </SpeakerWrapper>
                            :
                            !recordinOn && <SpeakerWrapper onClick={() => handleMicrophoneClick()}>
                                <Microphone />
                            </SpeakerWrapper>
                        }

                    </Line>


                    <LanguageContentInput ref={fromRef} placeholder='Enter text' value={from} onChange={(e) => setFrom(e.currentTarget.value)} onKeyDown={(e) => handleAddItemToTranslationHistory(e)} />

                </LanguageInput>

                {/* <DownItem>
                    <DownIcon />
                </DownItem> */}
                <LanguageInput >
                    <Line>
                        <LanguageSelector onClick={() => setToLanguageOpen(true)}>
                            <LanguageNameOutput>
                                {toLanguage}
                            </LanguageNameOutput>
                            <Unfold />
                        </LanguageSelector>
                        {to &&
                            <SpeakerWrapper onClick={() => handleSpeakClick(to)}>
                                <Speaker />
                            </SpeakerWrapper>
                        }
                    </Line>

                    <LanguageContentOutput readOnly ref={toRef} placeholder='Enter text' value={to} onClick={() => handleToClick()} />
                </LanguageInput>
            </Card>
            <HistoryList>


                {translationHistory.map((item, index) => (
                    <ReadOnlyTranslation
                        key={index}
                        fromLanguage={item.fromLanguage}
                        toLanguage={item.toLanguage}
                        initialText={item.initialText}
                        translatedText={item.translatedText}
                    />
                ))}
            </HistoryList>
        </Wrapper>
    )
}

export default TranslateComponent

const Wrapper = styled.div`
height:100%;
background:${colors.chatBg};
width:100%;
padding:50px;
display:flex;
flex-direction:column;
gap:30px;
width:100%;
overflow:auto;
align-items:center;
`

const Card = styled.div`
display:flex;
flex-direction:column;
border-radius:12px;
background:${colors.newChatModalBg};
min-width:400px;
width:100%;
min-height:350px;
overflow:hidden;
gap:3px;
position:relative;
`

const LanguageSelector = styled.div`
display:flex;
align-items:center;
gap:5px;
cursor:pointer;
svg{
height:16px;
}
`

const LanguageName = styled.p`
font-size:16px;
font-weight:500;
line-height:24px;
color:${colors.white};
`
const LanguageNameOutput = styled(LanguageName)`
color:${colors.purple};
`

const LanguageInput = styled.div`
display:flex;
flex-direction:column;
padding:20px;
gap:10px;
background:${colors.quickChatClosedBg};
`

const LanguageContentInput = styled.textarea`
min-height:100px;
height:100px;
max-height:100px;
resize:none;
background:transparent;
border:none;
outline:none;
font-size:20px;
font-weight:500;
color:${colors.chatElementColor};
`
const LanguageContentOutput = styled(LanguageContentInput)`
color:${colors.purple};
&::placeholder{
color:${colors.purple};
opacity:0.6;
}
`
const DownItem = styled.div`
display:flex;
align-items:center;
justify-content:center;

background:${colors.background};
border-radius:25px;
height:26px;
width:26px;
position:absolute;
top:calc(50% - 13px);
left:calc(50% - 13px );
svg{
width:12px;
color:${colors.purple};
}
`
const HistoryList = styled.div`
display:flex;
flex-direction:column-reverse;
gap:30px;
width:100%;
`
const SpeakerWrapper = styled.div`
cursor:pointer;
display:flex;
margin-left:auto;
align-items:center;
justify-content:center;
color:rgba(178,175,255,1);
`
const Line = styled.div`
display:flex;
aling-items:center;
gap:30px;
width:100%;
`

const StopRecordingBtn = styled.button`
display:flex;
align-items:center;
justify-content:center;
bottom:30px;
border-radius:50%;
min-width:60px;
max-width:60px;
max-height:60px;
min-height:60px;
border:none;
background:rgba(178,175,255,1);
z-index:5;
cursor:pointer;
bottom:30px;
left:50%;
position:absolute;
padding:20px;
&:hover{
scale:1.1;
}
`
const StopRecordingWrapper = styled.div`
position:absolute;
background:rgba(0,0,0,0.3);
top:0;
left:0;
right:0;
bottom:0;
z-index:2;
display:flex;
padding:20px;
`