import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { localizationStrings } from '../../Localization/Localization'


const ChatClosedInfo = () => {
    return (
        <Container>
            <p>
                Chat Closed
                {localizationStrings.quick_chat.close}
            </p>
        </Container>
    )
}

export default ChatClosedInfo
const Container = styled.div`
display:flex;
padding:8px 15px;
border-radius:10px;
background:${colors.newButtonOptionsBg};

p{
    color:${colors.white};
font-size: 16px;
font-weight: 500;
line-height: 22px;
text-align: center;
}

`