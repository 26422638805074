import React, { Suspense } from 'react'
import styled from 'styled-components';
import { colors } from '../../Assets/styles/colors';
import { ReactComponent as AvatarUnset } from '../../Assets/icons/unsetAvatar.svg'
import { ReactComponent as CallIcon } from "../../Assets/icons/calls/callIcon.svg"
import { ReactComponent as ChatIcon } from "../../Assets/icons/chatIcon.svg"
import { ReactComponent as ProBadge } from '../../Assets/icons/subscription/crown.svg'
import { ReactComponent as FreeBadge } from '../../Assets/icons/subscription/free.svg'
import { localizationStrings } from '../../Localization/Localization';

const FoundContactElement = ({ item, selected, handleSelectContact, invite, handleInvite, noCheck, callAction }) => {

  const handleClick = (item) => {
    if (invite) {
      handleInvite(item)
    } else {
      handleSelectContact(item)
    }
  }

  let displayName = item.name
  if (item.name === "") {
    displayName = item.phone_number
  }
  return (
    <Warpper  >
      <FoundContact onClick={() => handleClick(item)} >
        <FoundContactAvatar>
          {item.profile_picture ? <img src={item.profile_picture} alt='avatar' /> : <AvatarUnset />}
          {!invite &&
            <ProBadgeWrapper>
              {item.is_pro ? <ProBadge /> : <FreeBadge />}
            </ProBadgeWrapper>
          }
        </FoundContactAvatar>
        <p>{displayName}</p>

        {invite ?
          <InviteButton >
            {localizationStrings.invite}
          </InviteButton>
          :
          !noCheck &&
          <CustomCheckBox >
            <label className="container">
              <input
                type="checkbox"
                checked={selected}
                readOnly
              />
              <span
                className="checkmark">
              </span>
            </label>
          </CustomCheckBox>
        }


      </FoundContact>
      {
        callAction &&
        <CallBtnWrapper>
          <ChatIcon onClick={() => handleClick(item)} />
          <CallIcon onClick={() => callAction(item)} />
        </CallBtnWrapper>
      }
    </Warpper >

  )
}

export default FoundContactElement
const Warpper = styled.div`
z-index:1;
display:flex;
align-items:center;
`
const CallBtnWrapper = styled.div`
display:flex;
align-items:center;
cursor:pointer;
svg{
width:30px;
}
`
const FoundContact = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  gap: 10px;
  cursor:pointer;
  width:100%;
  p {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: ${colors.white};
    width: 100%;
  }
`;
const FoundContactAvatar = styled.div`
  display: flex;
  height:45px;
  width:45px;
  max-width: 45px;
  min-height: 45px;
  min-width: 45px;
  max-height: 45px;
  border-radius: 100%;
  position:relative;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius:100%;
  }

  svg{
    object-fit: cover;
   width: 100%;
   height: 100%;
 }
`;
const CustomCheckBox = styled.div`
display:block;
z-index:-1;
  .container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-left: 35px;
    margin-bottom: 25px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

  }

  /* Hide the browser's default checkbox */
  .container input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    z-index:0;
    top: 0;
    left: 0;
    height: 26px;
    width: 26px;
    border: 1px solid rgba(91, 91, 93, 1);
    border-radius: 50%;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: rgba(112, 105, 253, 1);
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 8px;
    top: 3px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const InviteButton = styled.button`
border:none;
background:none;
font-size: 16px;
font-weight: 400;
line-height: 22px;
color:${colors.purple};
cursor:pointer;
`
const ProBadgeWrapper = styled.div`
display:flex;
align-items:center;
justify-content:center;
position:absolute;
bottom:-5px;
right:-3px;
`

const CustomAvatar = styled.img`
border-radius:100px;
width:100%;
object-fit:cover;
height:40px;
width:40px;
position:relative;
`